import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Observable, catchError, exhaustMap, map, of, tap } from 'rxjs';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Member } from '@neuralegion/api';
import { MembersService } from '../services';
import {
  addMember,
  addMemberFail,
  addMemberSuccess,
  loadMember,
  loadMemberFail,
  loadMemberSuccess,
  loadMembers,
  loadMembersFail,
  loadMembersSuccess,
  removeMember,
  removeMemberFail,
  removeMemberSuccess,
  updateMember,
  updateMemberFail,
  updateMemberSuccess
} from './members.actions';

@Injectable()
export class MembersEffects {
  public readonly loadMember$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(loadMember),
      exhaustMap((action: ReturnType<typeof loadMember>) =>
        this.membersService.loadMember(action.payload.orgId, action.payload.memberId).pipe(
          map((member: Member) => loadMemberSuccess({ member })),
          catchError((err: HttpErrorResponse) => of(loadMemberFail(err.error)))
        )
      )
    )
  );

  public readonly loadMemberFail$: Observable<Action> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(loadMemberFail),
        tap(() => this.router.navigate(['/']))
      ),
    { dispatch: false }
  );

  public readonly loadMembers$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(loadMembers),
      exhaustMap((action: ReturnType<typeof loadMembers>) =>
        this.membersService.loadMembers(action.payload.orgId).pipe(
          map((members: Member[]) => loadMembersSuccess({ members })),
          catchError((err: HttpErrorResponse) => of(loadMembersFail(err.error)))
        )
      )
    )
  );

  public readonly updateMember$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(updateMember),
      exhaustMap(({ payload: { member } }: ReturnType<typeof updateMember>) =>
        this.membersService.updateMember(member).pipe(
          map(() => updateMemberSuccess({ orgId: member.organizationId, memberId: member.id })),
          catchError((err: HttpErrorResponse) => of(updateMemberFail(err.error)))
        )
      )
    )
  );

  public readonly addMember$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(addMember),
      exhaustMap(({ payload: { orgId, member } }: ReturnType<typeof addMember>) =>
        this.membersService.addMember(orgId, member).pipe(
          map(() => addMemberSuccess({ orgId })),
          catchError((err: HttpErrorResponse) => of(addMemberFail(err.error)))
        )
      )
    )
  );

  public readonly removeMember$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(removeMember),
      exhaustMap(({ payload: { orgId, memberId } }: ReturnType<typeof removeMember>) =>
        this.membersService.removeMember(orgId, memberId).pipe(
          map(() => removeMemberSuccess({ orgId, memberId })),
          catchError((err: HttpErrorResponse) => of(removeMemberFail(err.error)))
        )
      )
    )
  );

  public readonly reloadMember$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(updateMemberSuccess),
      map(({ payload: { orgId, memberId } }: ReturnType<typeof updateMemberSuccess>) =>
        loadMember({ orgId, memberId })
      )
    )
  );

  public readonly reloadMembers$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(addMemberSuccess),
      map(({ payload: { orgId } }: ReturnType<typeof addMemberSuccess>) => loadMembers({ orgId }))
    )
  );

  public readonly redirectToOrganizationPage$: Observable<Action> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(removeMemberSuccess),
        tap(({ payload: { orgId } }: ReturnType<typeof removeMemberSuccess>) =>
          this.router.navigate(['/organizations/', orgId])
        )
      ),
    { dispatch: false }
  );

  public readonly closeDialogWindows$: Observable<Action> = createEffect(
    () =>
      this.actions$.pipe(
        ofType<Action>(addMember),
        tap(() => this.dialog.closeAll())
      ),
    { dispatch: false }
  );

  constructor(
    private readonly actions$: Actions,
    private readonly router: Router,
    private readonly dialog: MatDialog,
    private readonly membersService: MembersService
  ) {}
}
