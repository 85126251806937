import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  BillingSummary,
  Plan,
  PlanOverviewData,
  Product,
  ProductCategory,
  Subscription
} from '@neuralegion/billing-api';
import { BillingAdminState } from './billing-admin.state';

// eslint-disable-next-line @typescript-eslint/ban-types
type State = object;
export const selectBillingAdminState = createFeatureSelector<BillingAdminState>('billingAdmin');

export const selectBillingSummary = createSelector<State, [BillingAdminState], BillingSummary>(
  selectBillingAdminState,
  (state: BillingAdminState) => state.summary
);

const selectProducts = createSelector<State, [BillingAdminState], Product[]>(
  selectBillingAdminState,
  (state: BillingAdminState) => state.products
);

export const selectAdminServiceProducts = createSelector<State, [Product[]], Product[]>(
  selectProducts,
  (products: Product[]) =>
    products?.filter((product) => product.category === ProductCategory.SERVICE)
);

const selectServicePlans = createSelector<State, [Product[]], Plan[]>(
  selectAdminServiceProducts,
  (products: Product[]) =>
    products?.reduce((res: Plan[], product) => [...res, ...product.plans], [])
);

export const selectCurrentServiceSubscription = createSelector<
  State,
  [BillingSummary, Plan[]],
  Subscription
>(selectBillingSummary, selectServicePlans, (summary: BillingSummary, plans: Plan[]) => {
  const servicePlanIds = new Set<string>(plans?.map((plan) => plan.id));
  return summary?.subscriptions.find((subscription: Subscription) =>
    servicePlanIds.has(subscription.plan?.id)
  );
});

export const selectCurrentServicePlan = createSelector<State, [Subscription], Plan>(
  selectCurrentServiceSubscription,
  (subscription: Subscription) => subscription?.plan
);

export const selectCurrentServiceProduct = createSelector<State, [Plan, Product[]], Product>(
  selectCurrentServicePlan,
  selectAdminServiceProducts,
  (currentPlan: Plan, products: Product[]) =>
    (products || []).find((product: Product) =>
      product.plans.some((plan: Plan) => plan.id === currentPlan?.id)
    )
);

export const selectPlanOverviewData = createSelector<
  State,
  [Subscription, Product],
  PlanOverviewData
>(
  selectCurrentServiceSubscription,
  selectCurrentServiceProduct,
  (subscription: Subscription, product: Product) => ({
    productName: product?.name,
    billingInterval: subscription?.plan.interval,
    status: subscription?.status,
    startDate: subscription?.startedAt ? new Date(subscription.startedAt) : null
  })
);

export const selectBillingAdminPending = createSelector<State, [BillingAdminState], boolean>(
  selectBillingAdminState,
  (state: BillingAdminState) => state.pending?.length > 0
);
