import { Environment } from '@neuralegion/api';

export const environment: Omit<Environment, 'production'> = {
  version: 'v4.91.0-next.11815544376',
  commit: '99f29e',

  reCaptchaSiteKey: '6LdnAakUAAAAACiqoGaDrqZXEYbcTCOPtc37ABJp',
  segmentWriteKey: '',
  sentryDsn: 'https://303c0a925ad74928b19e0832384bcbab@sentry.io/1809867',
  ipRestrictionsEnabled: false
};
