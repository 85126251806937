import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import {
  Organization,
  OrganizationPermissions,
  Quota,
  Role,
  quotasComparator
} from '@neuralegion/api';
import { CLEAR_EMPTY_VALUES_TOKEN } from '@neuralegion/core';

@Injectable()
export class OrganizationsService {
  constructor(private readonly http: HttpClient) {}

  public loadOrganizations(): Observable<Organization[]> {
    return this.http.get<Organization[]>('/api/v1/orgs');
  }

  public loadOrganization(orgId: string): Observable<Organization> {
    return this.http.get<Organization>(`/api/v1/orgs/${orgId}`);
  }

  public addOrganization(organization: Partial<Organization>): Observable<void> {
    return this.http.post<void>('/api/v1/orgs/', organization);
  }

  public updateOrganization(orgId: string, organization: Partial<Organization>): Observable<void> {
    return this.http.patch<void>(`/api/v1/orgs/${orgId}`, organization);
  }

  public loadRoles(orgId: string): Observable<Role[]> {
    return this.http.get<Role[]>(`/api/v1/orgs/${orgId}/roles`);
  }

  public updatePermissions(
    orgId: string,
    permissions: Partial<OrganizationPermissions>
  ): Observable<void> {
    return this.http.patch<void>(`/api/v1/orgs/${orgId}/permissions`, permissions);
  }

  public updateTags(orgId: string, tags: string[]): Observable<void> {
    return this.http.put<void>(
      `/api/v1/orgs/${orgId}/tags`,
      { tags },
      {
        context: new HttpContext().set(CLEAR_EMPTY_VALUES_TOKEN, false)
      }
    );
  }

  public setTargetDomainOptions(orgId: string, authorizedTargets: string[]): Observable<void> {
    return this.http.put<void>(`/api/v1/orgs/${orgId}/targets`, {
      authorizedTargets: authorizedTargets?.length ? authorizedTargets : ['brokencrystals.com']
    });
  }

  public uploadTargetDomainOptions(orgId: string, file: File): Observable<void> {
    const formData: FormData = new FormData();
    formData.append('file', file, file.name);

    return this.http.post<void>(`/api/v1/orgs/${orgId}/targets/upload`, formData, {
      context: new HttpContext().set(CLEAR_EMPTY_VALUES_TOKEN, false)
    });
  }

  public removeOrganization(orgId: string): Observable<void> {
    return this.http.delete<void>(`/api/v1/orgs/${orgId}`);
  }

  public loadQuotas(orgId: string): Observable<Quota[]> {
    return this.http
      .get<Quota[]>(`/api/v1/orgs/${orgId}/quotas`)
      .pipe(map((quotas: Quota[]) => [...quotas].sort(quotasComparator)));
  }

  public updateQuota(orgId: string, quota: Quota): Observable<void> {
    return this.http.put<void>(`/api/v1/orgs/${orgId}/quotas/${quota.type}`, quota);
  }
}
