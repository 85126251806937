import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Invitation, Member } from '@neuralegion/api';

@Injectable()
export class MembersService {
  constructor(private readonly http: HttpClient) {}

  public loadMembers(orgId: string): Observable<Member[]> {
    return this.http.get<Member[]>(`/api/v1/orgs/${orgId}/memberships`);
  }

  public loadMember(orgId: string, memberId: string): Observable<Member> {
    return this.http.get<Member>(`/api/v1/orgs/${orgId}/memberships/${memberId}`);
  }

  public addMember(orgId: string, payload: Invitation): Observable<void> {
    return this.http.post<void>(`/api/v1/orgs/${orgId}/memberships`, payload);
  }

  public updateMember(member: Member): Observable<void> {
    return this.http.patch<void>(
      `/api/v1/orgs/${member.organizationId}/memberships/${member.id}`,
      member
    );
  }

  public removeMember(orgId: string, memberId: string): Observable<void> {
    return this.http.delete<void>(`/api/v1/orgs/${orgId}/memberships/${memberId}`);
  }
}
