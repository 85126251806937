import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, map, of, switchMap, withLatestFrom } from 'rxjs';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { Scope } from '@neuralegion/api';
import { selectScopePermission } from '@neuralegion/auth-api';
import { BillingSummary, Product } from '@neuralegion/billing-api';
import { loadOrganization, loadQuotas } from '../../organizations-api';
import { BillingAdminService } from '../services';
import {
  addSubscription,
  addSubscriptionFail,
  addSubscriptionSuccess,
  changeSubscription,
  changeSubscriptionFail,
  changeSubscriptionSuccess,
  loadProducts,
  loadProductsFail,
  loadProductsSuccess,
  loadSummary,
  loadSummaryFail,
  loadSummarySuccess,
  removeSubscription,
  removeSubscriptionFail,
  removeSubscriptionSuccess
} from './billing-admin.actions';

@Injectable()
export class BillingAdminEffects {
  public readonly loadSummary$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(loadSummary),
      withLatestFrom(this.store.select(selectScopePermission(Scope.ADMIN_BILLINGS))),
      switchMap(
        ([action, adminBillingsPermission]: [
          ReturnType<typeof loadSummary>,
          boolean
        ]): Observable<Action> =>
          adminBillingsPermission
            ? this.billingAdminService.loadSummary(action.payload.customerId).pipe(
                map((summary: BillingSummary) => loadSummarySuccess({ summary })),
                catchError((err: HttpErrorResponse) => of(loadSummaryFail({ error: err.error })))
              )
            : of(loadSummaryFail({ error: 'Forbidden' }))
      )
    )
  );

  public readonly loadProducts$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(loadProducts),
      withLatestFrom(this.store.select(selectScopePermission(Scope.ADMIN_BILLINGS))),
      switchMap(([, adminBillingsPermission]: [ReturnType<typeof loadProducts>, boolean]) =>
        adminBillingsPermission
          ? this.billingAdminService.loadProducts().pipe(
              map((products: Product[]) => loadProductsSuccess({ products })),
              catchError((err: HttpErrorResponse) => of(loadProductsFail({ error: err.error })))
            )
          : of(loadProductsFail({ error: 'Forbidden' }))
      )
    )
  );

  public readonly addSubscription$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(addSubscription),
      switchMap(
        (action: ReturnType<typeof addSubscription>): Observable<Action> =>
          this.billingAdminService
            .addSubscription(action.payload.orgId, action.payload.planId)
            .pipe(
              map(() => addSubscriptionSuccess({ customerId: action.payload.orgId })),
              catchError((err: HttpErrorResponse) => of(addSubscriptionFail(err.error)))
            )
      )
    )
  );

  public readonly changeSubscription$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(changeSubscription),
      switchMap(
        (action: ReturnType<typeof changeSubscription>): Observable<Action> =>
          this.billingAdminService
            .changeSubscription(
              action.payload.orgId,
              action.payload.planId,
              action.payload.subscriptionId
            )
            .pipe(
              map(() => changeSubscriptionSuccess({ customerId: action.payload.orgId })),
              catchError((err: HttpErrorResponse) => of(changeSubscriptionFail(err.error)))
            )
      )
    )
  );

  public readonly removeSubscription$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(removeSubscription),
      switchMap(
        (action: ReturnType<typeof removeSubscription>): Observable<Action> =>
          this.billingAdminService
            .removeSubscription(action.payload.orgId, action.payload.subscriptionId)
            .pipe(
              map(() => removeSubscriptionSuccess({ customerId: action.payload.orgId })),
              catchError((err: HttpErrorResponse) => of(removeSubscriptionFail(err.error)))
            )
      )
    )
  );

  public readonly refreshSubscription$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(addSubscriptionSuccess, changeSubscriptionSuccess, removeSubscriptionSuccess),
      switchMap(
        (
          action: ReturnType<
            | typeof addSubscriptionSuccess
            | typeof changeSubscriptionSuccess
            | typeof removeSubscriptionSuccess
          >
        ) => [
          loadOrganization({ orgId: action.payload.customerId }),
          loadQuotas({ orgId: action.payload.customerId })
        ]
      )
    )
  );

  constructor(
    private readonly actions$: Actions,
    private readonly store: Store,
    private readonly billingAdminService: BillingAdminService
  ) {}
}
