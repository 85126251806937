import { MemoizedSelector, createFeatureSelector, createSelector } from '@ngrx/store';
import { OrgRole, OrgRolesState, orgRolesEntityAdapter } from './org-roles.state';
import { State } from './state';

export const selectOrgRolesState = createFeatureSelector<OrgRolesState>('orgRoles');

const selectAllRoles = createSelector<State, [OrgRolesState], OrgRole[]>(
  selectOrgRolesState,
  orgRolesEntityAdapter.getSelectors().selectAll
);

export const selectOrgRoles = (organizationId: string): MemoizedSelector<State, OrgRole[]> =>
  createSelector<State, [OrgRole[]], OrgRole[]>(selectAllRoles, (roles: OrgRole[]) =>
    roles.filter((item: OrgRole) => item.organizationId === organizationId)
  );

export const selectOrgRolesMap = (
  organizationId: string
): MemoizedSelector<State, Map<string, OrgRole>> =>
  createSelector<State, [OrgRole[]], Map<string, OrgRole>>(
    selectOrgRoles(organizationId),
    (roles: OrgRole[]) => new Map<string, OrgRole>(roles.map((role) => [role.id, role]))
  );

export const selectOrgRolesPendingStatus = createSelector<State, [OrgRolesState], boolean>(
  selectOrgRolesState,
  (state: OrgRolesState) => state.pending?.length > 0
);
