import { Action, createReducer, on } from '@ngrx/store';
import { cleanupPendingState } from '@neuralegion/core';
import {
  addOrganization,
  addOrganizationFail,
  addOrganizationSuccess,
  loadOrganization,
  loadOrganizationFail,
  loadOrganizationSuccess,
  loadOrganizations,
  loadOrganizationsFail,
  loadOrganizationsSuccess,
  removeOrganization,
  removeOrganizationFail,
  removeOrganizationSuccess,
  setTargetDomainOptions,
  setTargetDomainOptionsFail,
  setTargetDomainOptionsSuccess,
  updateOrganization,
  updateOrganizationFail,
  updateOrganizationSuccess,
  updatePermissions,
  updatePermissionsFail,
  updatePermissionsSuccess,
  updateTags,
  updateTagsFail,
  updateTagsSuccess,
  uploadTargetDomainOptions,
  uploadTargetDomainOptionsFail,
  uploadTargetDomainOptionsSuccess
} from './organizations.actions';
import {
  OrganizationsState,
  initialOrganizationsState,
  organizationsEntityAdapter
} from './organizations.state';

export const organizationsReducer = createReducer<OrganizationsState, Action>(
  initialOrganizationsState,
  on(
    loadOrganization,
    loadOrganizations,
    addOrganization,
    updateOrganization,
    updatePermissions,
    updateTags,
    removeOrganization,
    setTargetDomainOptions,
    uploadTargetDomainOptions,
    (state: OrganizationsState, action): OrganizationsState => ({
      ...state,
      pending: [...state.pending, action],
      error: null
    })
  ),
  on(
    loadOrganizationFail,
    loadOrganizationsFail,
    addOrganizationFail,
    updateOrganizationFail,
    updateTagsFail,
    updatePermissionsFail,
    removeOrganizationFail,
    setTargetDomainOptionsFail,
    uploadTargetDomainOptionsFail,
    (state: OrganizationsState, action): OrganizationsState =>
      cleanupPendingState(state, action, action.payload)
  ),
  on(
    loadOrganizationSuccess,
    (state: OrganizationsState, action): OrganizationsState =>
      organizationsEntityAdapter.upsertOne(action.payload, cleanupPendingState(state, action))
  ),
  on(
    loadOrganizationsSuccess,
    (state: OrganizationsState, action): OrganizationsState =>
      organizationsEntityAdapter.setAll(action.payload, cleanupPendingState(state, action))
  ),
  on(
    addOrganizationSuccess,
    updateOrganizationSuccess,
    updatePermissionsSuccess,
    updateTagsSuccess,
    setTargetDomainOptionsSuccess,
    uploadTargetDomainOptionsSuccess,
    (state: OrganizationsState, action): OrganizationsState => cleanupPendingState(state, action)
  ),
  on(
    removeOrganizationSuccess,
    (state: OrganizationsState, action): OrganizationsState =>
      organizationsEntityAdapter.removeOne(action.payload.orgId, cleanupPendingState(state, action))
  )
);
