import { createAction } from '@ngrx/store';
import { Organization, OrganizationPermissions } from '@neuralegion/api';

export const loadOrganizations = createAction('[Organizations] LOAD_ALL');

export const loadOrganizationsSuccess = createAction(
  '[Organizations] LOAD_ALL_SUCCESS',
  (payload: Organization[]) => ({
    payload,
    parentType: loadOrganizations.type
  })
);

export const loadOrganizationsFail = createAction(
  '[Organizations] LOAD_ALL_FAIL',
  (payload: string) => ({
    payload,
    parentType: loadOrganizations.type
  })
);

export const loadOrganization = createAction(
  '[Organizations] LOAD',
  (payload: { orgId: string; skipRelated?: boolean }) => ({ payload })
);

export const loadOrganizationSuccess = createAction(
  '[Organizations] LOAD_SUCCESS',
  (payload: Organization) => ({
    payload,
    parentType: loadOrganization.type
  })
);

export const loadOrganizationFail = createAction(
  '[Organizations] LOAD_FAIL',
  (payload: string) => ({
    payload,
    parentType: loadOrganization.type
  })
);

export const addOrganization = createAction(
  '[Organizations] ADD',
  (payload: Partial<Organization>) => ({ payload })
);

export const addOrganizationSuccess = createAction('[Organizations] ADD_SUCCESS', () => ({
  parentType: addOrganization.type
}));

export const addOrganizationFail = createAction('[Organizations] ADD_FAIL', (payload: string) => ({
  payload,
  parentType: addOrganization.type
}));

export const removeOrganization = createAction(
  '[Organizations] REMOVE',
  (payload: { orgId: string }) => ({ payload })
);

export const removeOrganizationSuccess = createAction(
  '[Organizations] REMOVE_SUCCESS',
  (payload: { orgId: string }) => ({ payload, parentType: removeOrganization.type })
);

export const removeOrganizationFail = createAction(
  '[Organizations] REMOVE_FAIL',
  (payload: string) => ({
    payload,
    parentType: removeOrganization.type
  })
);

export const updateOrganization = createAction(
  '[Organizations] UPDATE',
  (payload: { orgId: string; organization: Partial<Organization> }) => ({ payload })
);

export const updateOrganizationSuccess = createAction(
  '[Organizations] UPDATE_SUCCESS',
  (payload: { orgId: string }) => ({ payload, parentType: updateOrganization.type })
);

export const updateOrganizationFail = createAction(
  '[Organizations] UPDATE_FAIL',
  (payload: string) => ({
    payload,
    parentType: updateOrganization.type
  })
);

export const updatePermissions = createAction(
  '[Organizations] UPDATE_PERMISSIONS',
  (payload: { orgId: string; permissions: Partial<OrganizationPermissions> }) => ({ payload })
);

export const updatePermissionsSuccess = createAction(
  '[Organizations] UPDATE_PERMISSIONS_SUCCESS',
  (payload: { orgId: string }) => ({ payload, parentType: updatePermissions.type })
);

export const updatePermissionsFail = createAction(
  '[Organizations] UPDATE_PERMISSIONS_FAIL',
  (payload: string) => ({
    payload,
    parentType: updatePermissions.type
  })
);

export const updateTags = createAction(
  '[Organizations] UPDATE_TAGS',
  (payload: { orgId: string; tags: string[] }) => ({
    payload
  })
);

export const updateTagsSuccess = createAction(
  '[Organizations] UPDATE_TAGS_SUCCESS',
  (payload: { orgId: string }) => ({
    payload,
    parentType: updateTags.type
  })
);

export const updateTagsFail = createAction(
  '[Organizations] UPDATE_TAGS_FAIL',
  (payload: string) => ({
    payload,
    parentType: updateTags.type
  })
);

export const setTargetDomainOptions = createAction(
  '[Organizations] SET_TARGET_DOMAIN_OPTIONS',
  (payload: { orgId: string; authorizedTargets: string[] }) => ({ payload })
);

export const setTargetDomainOptionsSuccess = createAction(
  '[Organizations] SET_TARGET_DOMAIN_OPTIONS_SUCCESS',
  (payload: { orgId: string }) => ({ payload, parentType: setTargetDomainOptions.type })
);

export const setTargetDomainOptionsFail = createAction(
  '[Organizations] SET_TARGET_DOMAIN_OPTIONS_FAIL',
  (payload: string) => ({ payload, parentType: setTargetDomainOptions.type })
);

export const uploadTargetDomainOptions = createAction(
  '[Organizations] UPLOAD_TARGET_DOMAIN_OPTIONS',
  (payload: { orgId: string; file: File }) => ({ payload })
);

export const uploadTargetDomainOptionsSuccess = createAction(
  '[Organizations] UPLOAD_TARGET_DOMAIN_OPTIONS_SUCCESS',
  (payload: { orgId: string }) => ({ payload, parentType: uploadTargetDomainOptions.type })
);

export const uploadTargetDomainOptionsFail = createAction(
  '[Organizations] UPLOAD_TARGET_DOMAIN_OPTIONS_FAIL',
  (payload: string) => ({ payload, parentType: uploadTargetDomainOptions.type })
);
