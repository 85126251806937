import { Action, createReducer, on } from '@ngrx/store';
import { cleanupPendingState } from '@neuralegion/core';
import {
  addSubscription,
  addSubscriptionFail,
  addSubscriptionSuccess,
  changeSubscription,
  changeSubscriptionFail,
  changeSubscriptionSuccess,
  loadProducts,
  loadProductsFail,
  loadProductsSuccess,
  loadSummary,
  loadSummaryFail,
  loadSummarySuccess,
  removeSubscription,
  removeSubscriptionFail,
  removeSubscriptionSuccess
} from './billing-admin.actions';
import { BillingAdminState, initialBillingAdminState } from './billing-admin.state';

export const billingAdminReducer = createReducer<BillingAdminState, Action>(
  initialBillingAdminState,
  on(
    loadSummary,
    loadProducts,
    addSubscription,
    changeSubscription,
    removeSubscription,
    (state: BillingAdminState, action): BillingAdminState => ({
      ...state,
      pending: [...state.pending, action],
      error: null
    })
  ),
  on(
    loadSummarySuccess,
    (state: BillingAdminState, action): BillingAdminState =>
      cleanupPendingState({ ...state, summary: action.payload.summary }, action)
  ),
  on(
    loadProductsSuccess,
    (state: BillingAdminState, action): BillingAdminState =>
      cleanupPendingState({ ...state, products: action.payload.products }, action)
  ),
  on(
    addSubscriptionSuccess,
    changeSubscriptionSuccess,
    removeSubscriptionSuccess,
    (state: BillingAdminState, action): BillingAdminState => cleanupPendingState(state, action)
  ),
  on(
    loadSummaryFail,
    loadProductsFail,
    (state: BillingAdminState, action): BillingAdminState =>
      cleanupPendingState(state, action, action.payload.error)
  ),
  on(
    addSubscriptionFail,
    changeSubscriptionFail,
    removeSubscriptionFail,
    (state: BillingAdminState, action): BillingAdminState =>
      cleanupPendingState(state, action, action.payload)
  )
);
