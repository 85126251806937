import { MemoizedSelector, createFeatureSelector, createSelector } from '@ngrx/store';
import { Member } from '@neuralegion/api';
import { MembersState, membersEntityAdapter } from './members.state';
import { State } from './state';

export const selectMembersState = createFeatureSelector<MembersState>('members');

export const selectMemberById = (memberId: string): MemoizedSelector<State, Member> =>
  createSelector<State, [MembersState], Member>(
    selectMembersState,
    (state: MembersState) => state.entities[memberId]
  );

const selectAllMembers = createSelector<State, [MembersState], Member[]>(
  selectMembersState,
  membersEntityAdapter.getSelectors().selectAll
);

export const selectMembers = (organizationId: string): MemoizedSelector<State, Member[]> =>
  createSelector<State, [Member[]], Member[]>(selectAllMembers, (members: Member[]) =>
    members.filter((item: Member) => item.organizationId === organizationId)
  );

export const selectMembersPendingStatus = createSelector<State, [MembersState], boolean>(
  selectMembersState,
  (state: MembersState) => state.pending && state.pending.length > 0
);
