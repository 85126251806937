import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, exhaustMap, map, of } from 'rxjs';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Role } from '@neuralegion/api';
import { OrganizationsService } from '../services';
import { loadOrgRoles, loadOrgRolesFail, loadOrgRolesSuccess } from './org-roles.actions';

@Injectable()
export class OrgRolesEffects {
  public readonly loadRoles$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(loadOrgRoles),
      exhaustMap((action: ReturnType<typeof loadOrgRoles>) =>
        this.organizationsService.loadRoles(action.payload.orgId).pipe(
          map((roles: Role[]) =>
            loadOrgRolesSuccess({
              roles: roles.map((role) => ({ ...role, organizationId: action.payload.orgId }))
            })
          ),
          catchError((err: HttpErrorResponse) => of(loadOrgRolesFail(err.error)))
        )
      )
    )
  );

  constructor(
    private readonly actions$: Actions,
    private readonly organizationsService: OrganizationsService
  ) {}
}
