import { Action, createReducer, on } from '@ngrx/store';
import { quotasComparator } from '@neuralegion/api';
import { cleanupPendingState } from '@neuralegion/core';
import {
  clearOrgQuotas,
  loadQuotas,
  loadQuotasFail,
  loadQuotasSuccess,
  updateQuota,
  updateQuotaFail,
  updateQuotaSuccess
} from './org-quotas.actions';
import { OrgQuotasState, orgQuotasEntityAdapter, orgQuotasInitialState } from './org-quotas.state';

export const orgQuotasReducer = createReducer<OrgQuotasState, Action>(
  orgQuotasInitialState,
  on(
    loadQuotas,
    updateQuota,
    (state: OrgQuotasState, action): OrgQuotasState => ({
      ...state,
      pending: [...state.pending, action]
    })
  ),
  on(
    loadQuotasFail,
    updateQuotaFail,
    (state: OrgQuotasState, action): OrgQuotasState =>
      cleanupPendingState(state, action, action.payload)
  ),
  on(
    loadQuotasSuccess,
    (state: OrgQuotasState, action): OrgQuotasState =>
      orgQuotasEntityAdapter.upsertOne(
        {
          organizationId: action.payload.orgId,
          quotas: action.payload.quotas
        },
        cleanupPendingState(state, action)
      )
  ),
  on(
    updateQuotaSuccess,
    (state: OrgQuotasState, action): OrgQuotasState =>
      orgQuotasEntityAdapter.upsertOne(
        {
          organizationId: action.payload.orgId,
          quotas: [
            ...state.entities[action.payload.orgId].quotas.filter(
              (quota) => quota.type !== action.payload.quota.type
            ),
            action.payload.quota
          ].sort(quotasComparator)
        },
        cleanupPendingState(state, action)
      )
  ),
  on(
    clearOrgQuotas,
    (state: OrgQuotasState, action): OrgQuotasState =>
      orgQuotasEntityAdapter.removeOne(action.payload.orgId, state)
  )
);
