import { DOCUMENT } from '@angular/common';
import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {
  Event,
  NavigationCancel,
  NavigationError,
  RouteConfigLoadEnd,
  RouteConfigLoadStart,
  Router
} from '@angular/router';
import { Observable, combineLatest, distinctUntilChanged, map } from 'rxjs';
import { Store } from '@ngrx/store';
import { selectAuthActiveStatus } from '@neuralegion/auth-api';
import { activateLayout, deactivateLayout, selectPendingStatus } from '@neuralegion/core';

@Component({
  selector: 'admin-root',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template:
    '<share-spinner *ngIf="pending$ | async"></share-spinner><router-outlet></router-outlet>'
})
export class RootComponent implements OnInit {
  public pending$: Observable<boolean>;

  constructor(
    @Inject(DOCUMENT) private readonly document: Document,
    private readonly matDialog: MatDialog,
    private readonly router: Router,
    private readonly store: Store
  ) {}

  public ngOnInit(): void {
    this.pending$ = combineLatest([
      this.store.select(selectPendingStatus),
      this.store.select(selectAuthActiveStatus),
      this.matDialog.afterAllClosed.pipe(map(() => this.matDialog.openDialogs.length === 0))
    ]).pipe(
      map((flags: boolean[]) => flags.every(Boolean)),
      distinctUntilChanged()
    );

    this.router.events.subscribe((event: Event) => {
      if (event instanceof RouteConfigLoadStart) {
        this.store.dispatch(deactivateLayout());
      } else if (
        event instanceof RouteConfigLoadEnd ||
        event instanceof NavigationCancel ||
        event instanceof NavigationError
      ) {
        this.store.dispatch(activateLayout());
      }
    });

    this.document.defaultView.addEventListener('pageshow', (event: PageTransitionEvent) => {
      if (event.persisted) {
        this.store.dispatch(activateLayout());
      }
    });
  }
}
