import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { BillingAdminService } from '../services';
import { BillingAdminEffects } from '../store/billing-admin.effects';
import { billingAdminReducer } from '../store/billing-admin.reducer';

@NgModule({
  imports: [
    StoreModule.forFeature('billingAdmin', billingAdminReducer),
    EffectsModule.forFeature([BillingAdminEffects])
  ],
  providers: [BillingAdminService]
})
export class BillingAdminApiModule {}
