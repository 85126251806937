import { Action } from '@ngrx/store';
import { BillingSummary, Product } from '@neuralegion/billing-api';

export interface BillingAdminState {
  pending: Action[];
  error: string;
  products: Product[];
  summary: BillingSummary;
}

export const initialBillingAdminState: BillingAdminState = {
  pending: [],
  error: null,
  products: [],
  summary: null
};
