import { EntityAdapter, EntityState, createEntityAdapter } from '@ngrx/entity';
import { Action } from '@ngrx/store';
import { Organization } from '@neuralegion/api';

export interface OrganizationsState extends EntityState<Organization> {
  error?: string;
  pending: Action[];
}

export const organizationsEntityAdapter: EntityAdapter<Organization> =
  createEntityAdapter<Organization>({
    selectId: (model) => model.id
  });

export const initialOrganizationsState: OrganizationsState =
  organizationsEntityAdapter.getInitialState({
    error: null,
    pending: []
  });
