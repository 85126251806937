import { Injectable } from '@angular/core';
import { Observable, exhaustMap, filter, map, take } from 'rxjs';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { trackEvent } from '@neuralegion/analytics-api';
import { Organization } from '@neuralegion/api';
import { addMember } from './members.actions';
import { addOrganization, updateOrganization } from './organizations.actions';
import { selectOrganizationById } from './organizations.selectors';

@Injectable()
export class AdminAnalyticsEffects {
  public readonly trackAddMember$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(addMember),
      map((action: ReturnType<typeof addMember>) =>
        trackEvent({
          name: 'Invite Member Form Submitted',
          properties: {
            /* eslint-disable @typescript-eslint/naming-convention */
            org_id: action.payload.orgId,
            invitation_full_name: action.payload.member.name,
            invitation_email: action.payload.member.email,
            invitation_role_id: action.payload.member.roleId,
            invitation_groups: action.payload.member.groups
            /* eslint-enable @typescript-eslint/naming-convention */
          }
        })
      )
    )
  );

  public readonly trackAddOrganization$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(addOrganization),
      map((action: ReturnType<typeof addOrganization>) =>
        trackEvent({
          name: 'Create Organization Form Submitted',
          properties: {
            // eslint-disable-next-line @typescript-eslint/naming-convention
            org_name: action.payload.name
          }
        })
      )
    )
  );

  public readonly trackUpdateOrganizationName$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(updateOrganization),
      exhaustMap((action: ReturnType<typeof updateOrganization>) =>
        this.store.select(selectOrganizationById(action.payload.orgId)).pipe(
          map(
            (organization: Organization): [ReturnType<typeof updateOrganization>, Organization] => [
              action,
              organization
            ]
          ),
          take(1)
        )
      ),
      filter(
        ([action, organization]: [ReturnType<typeof updateOrganization>, Organization]) =>
          action.payload.organization.name !== organization.name
      ),
      map(([action]: [ReturnType<typeof updateOrganization>, Organization]) => action),
      map((action: ReturnType<typeof updateOrganization>) =>
        trackEvent({
          name: 'Update Organization Form Submitted',
          properties: {
            // eslint-disable-next-line @typescript-eslint/naming-convention
            org_id: action.payload.orgId,
            // eslint-disable-next-line @typescript-eslint/naming-convention
            org_name: action.payload.organization.name
          }
        })
      )
    )
  );

  constructor(
    private readonly actions$: Actions,
    private readonly store: Store
  ) {}
}
