import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BillingSummary, Product, Subscription } from '@neuralegion/billing-api';

@Injectable()
export class BillingAdminService {
  constructor(protected readonly http: HttpClient) {}

  public loadSummary(customerId: string): Observable<BillingSummary> {
    return this.http.get<BillingSummary>(`/api/v1/customers/${customerId}/billing`);
  }

  public loadProducts(): Observable<Product[]> {
    return this.http.get<Product[]>('/api/v1/products');
  }

  public addSubscription(organizationId: string, planId: string): Observable<Subscription> {
    return this.http.post<Subscription>(
      `/api/v1/customers/${organizationId}/billing/subscriptions`,
      {
        planId,
        quantity: 1
      }
    );
  }

  public changeSubscription(
    organizationId: string,
    planId: string,
    subscriptionId: string
  ): Observable<Subscription> {
    return this.http.patch<Subscription>(
      `/api/v1/customers/${organizationId}/billing/subscriptions/${subscriptionId}`,
      {
        planId,
        quantity: 1
      }
    );
  }

  public removeSubscription(organizationId: string, subscriptionId: string): Observable<void> {
    return this.http.delete<void>(
      `/api/v1/customers/${organizationId}/billing/subscriptions/${subscriptionId}`
    );
  }
}
