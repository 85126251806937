import { MemoizedSelector, createFeatureSelector, createSelector } from '@ngrx/store';
import { Quota } from '@neuralegion/api';
import { OrgQuotasState } from './org-quotas.state';
import { State } from './state';

export const selectOrgQuotasState = createFeatureSelector<OrgQuotasState>('orgQuotas');

export const selectOrganizationQuotas = (
  organizationId: string
): MemoizedSelector<State, Quota[]> =>
  createSelector<State, [OrgQuotasState], Quota[]>(
    selectOrgQuotasState,
    (state: OrgQuotasState) => state.entities[organizationId]?.quotas
  );

export const selectOrgQuotasPendingStatus = createSelector<State, [OrgQuotasState], boolean>(
  selectOrgQuotasState,
  (state: OrgQuotasState) => state.pending?.length > 0
);
