import { Environment } from '@neuralegion/api';
import { environment as defaultEnvironment } from './environment.defaults';

export const environment: Environment = {
  ...defaultEnvironment,
  production: false,

  segmentWriteKey: 'VYMAy2VbHp9WtSluHzWFyg7mhh31EME0',
  ipRestrictionsEnabled: true
};
