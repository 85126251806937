import { Action, createReducer, on } from '@ngrx/store';
import { cleanupPendingState } from '@neuralegion/core';
import {
  clearOrgRoles,
  loadOrgRoles,
  loadOrgRolesFail,
  loadOrgRolesSuccess
} from './org-roles.actions';
import {
  OrgRole,
  OrgRolesState,
  orgRolesEntityAdapter,
  orgRolesInitialState
} from './org-roles.state';

export const orgRolesReducer = createReducer<OrgRolesState, Action>(
  orgRolesInitialState,
  on(
    loadOrgRoles,
    (state: OrgRolesState, action): OrgRolesState => ({
      ...state,
      pending: [...state.pending, action]
    })
  ),
  on(
    loadOrgRolesFail,
    (state: OrgRolesState, action): OrgRolesState =>
      cleanupPendingState(state, action, action.payload)
  ),
  on(
    loadOrgRolesSuccess,
    (state: OrgRolesState, action): OrgRolesState =>
      orgRolesEntityAdapter.addMany(action.payload.roles, cleanupPendingState(state, action))
  ),
  on(
    clearOrgRoles,
    (state: OrgRolesState, action): OrgRolesState =>
      orgRolesEntityAdapter.removeMany(
        (item: OrgRole) => item.organizationId === action.payload.orgId,
        state
      )
  )
);
