import { EntityAdapter, EntityState, createEntityAdapter } from '@ngrx/entity';
import { Action } from '@ngrx/store';
import { Quota } from '@neuralegion/api';

export interface OrgQuotas {
  quotas: Quota[];
  organizationId: string;
}

export interface OrgQuotasState extends EntityState<OrgQuotas> {
  error: string;
  pending: Action[];
}

export const orgQuotasEntityAdapter: EntityAdapter<OrgQuotas> = createEntityAdapter<OrgQuotas>({
  selectId: (model) => model.organizationId
});

export const orgQuotasInitialState: OrgQuotasState = orgQuotasEntityAdapter.getInitialState({
  error: null,
  pending: []
});
