import { MemoizedSelector, createFeatureSelector, createSelector } from '@ngrx/store';
import { Organization } from '@neuralegion/api';
import { OrganizationsState, organizationsEntityAdapter } from './organizations.state';
import { State } from './state';

export const selectOrganizationsState = createFeatureSelector<OrganizationsState>('organizations');

export const selectOrganizationById = (
  organizationId: string
): MemoizedSelector<State, Organization> =>
  createSelector<State, [OrganizationsState], Organization>(
    selectOrganizationsState,
    (state: OrganizationsState) => state.entities[organizationId]
  );

export const selectAllOrganizations = createSelector<State, [OrganizationsState], Organization[]>(
  selectOrganizationsState,
  organizationsEntityAdapter.getSelectors().selectAll
);

export const selectOrganizationsPendingStatus = createSelector<
  State,
  [OrganizationsState],
  boolean
>(
  selectOrganizationsState,
  (state: OrganizationsState) => state.pending && state.pending.length > 0
);
