import { createAction } from '@ngrx/store';
import { Invitation, Member } from '@neuralegion/api';

export const loadMember = createAction(
  '[Members] LOAD_MEMBER',
  (payload: { orgId: string; memberId: string }) => ({
    payload
  })
);

export const loadMemberSuccess = createAction(
  '[Members] LOAD_MEMBER_SUCCESS',
  (payload: { member: Member }) => ({
    payload,
    parentType: loadMember.type
  })
);

export const loadMemberFail = createAction('[Members] LOAD_MEMBER_FAIL', (payload: string) => ({
  payload,
  parentType: loadMember.type
}));

export const addMember = createAction(
  '[Members] ADD_MEMBER',
  (payload: { orgId: string; member: Invitation }) => ({
    payload
  })
);

export const addMemberSuccess = createAction(
  '[Members] ADD_MEMBER_SUCCESS',
  (payload: { orgId: string }) => ({
    payload,
    parentType: addMember.type
  })
);

export const addMemberFail = createAction('[Members] ADD_MEMBER_FAIL', (payload: string) => ({
  payload,
  parentType: addMember.type
}));

export const updateMember = createAction(
  '[Members] UPDATE_MEMBER',
  (payload: { member: Member }) => ({ payload })
);

export const updateMemberSuccess = createAction(
  '[Members] UPDATE_MEMBER_SUCCESS',
  (payload: { orgId: string; memberId: string }) => ({ payload, parentType: updateMember.type })
);

export const updateMemberFail = createAction('[Members] UPDATE_MEMBER_FAIL', (payload: string) => ({
  payload,
  parentType: updateMember.type
}));

export const removeMember = createAction(
  '[Members] REMOVE_MEMBER',
  (payload: { orgId: string; memberId: string }) => ({
    payload
  })
);

export const removeMemberSuccess = createAction(
  '[Members] REMOVE_MEMBER_SUCCESS',
  (payload: { orgId: string; memberId: string }) => ({ payload, parentType: removeMember.type })
);

export const removeMemberFail = createAction('[Members] REMOVE_MEMBER_FAIL', (payload: string) => ({
  payload,
  parentType: removeMember.type
}));

export const loadMembers = createAction('[Members] LOAD_MEMBERS', (payload: { orgId: string }) => ({
  payload
}));

export const loadMembersSuccess = createAction(
  '[Members] LOAD_MEMBERS_SUCCESS',
  (payload: { members: Member[] }) => ({
    payload,
    parentType: loadMembers.type
  })
);

export const loadMembersFail = createAction('[Members] LOAD_MEMBERS_FAIL', (payload: string) => ({
  payload,
  parentType: loadMembers.type
}));

export const clearOrgMembers = createAction(
  '[Members] CLEAR_ORG_MEMBERS',
  (payload: { orgId: string }) => ({
    payload
  })
);
