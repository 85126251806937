import { EntityAdapter, EntityState, createEntityAdapter } from '@ngrx/entity';
import { Action } from '@ngrx/store';
import { Role } from '@neuralegion/api';

export interface OrgRole extends Role {
  organizationId: string;
}

export interface OrgRolesState extends EntityState<OrgRole> {
  error: string;
  pending: Action[];
}

export const orgRolesEntityAdapter: EntityAdapter<OrgRole> = createEntityAdapter<OrgRole>({
  selectId: (model) => `${model.id}-${model.organizationId}`
});

export const orgRolesInitialState: OrgRolesState = orgRolesEntityAdapter.getInitialState({
  error: null,
  pending: []
});
