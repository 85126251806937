import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AuthGuard, SuperAdminGuard } from '@neuralegion/auth-api';
import { DateSettingsGuard } from '@neuralegion/profile-api';
import { AppComponent } from '../containers';

@NgModule({
  imports: [
    RouterModule.forRoot([
      {
        path: '',
        component: AppComponent,
        data: { adminApp: true },
        children: [
          { path: '', redirectTo: '/organizations', pathMatch: 'full' },
          {
            path: 'organizations',
            canActivate: [AuthGuard, SuperAdminGuard],
            canMatch: [AuthGuard, SuperAdminGuard, DateSettingsGuard],
            loadChildren: async () => (await import('../../libs/organizations')).OrganizationsModule
          },
          {
            path: 'tags',
            canActivate: [AuthGuard, SuperAdminGuard],
            canMatch: [AuthGuard, SuperAdminGuard, DateSettingsGuard],
            loadChildren: async () =>
              (await import('../../libs/organization-tags')).OrganizationTagsModule
          },
          {
            path: 'profile',
            canActivate: [AuthGuard, SuperAdminGuard],
            canMatch: [AuthGuard, SuperAdminGuard, DateSettingsGuard],
            loadChildren: async () => (await import('@neuralegion/profile')).ProfileModule
          },
          {
            path: 'issue-types',
            canActivate: [AuthGuard, SuperAdminGuard],
            canMatch: [AuthGuard, SuperAdminGuard, DateSettingsGuard],
            loadChildren: async () => (await import('../../libs/issue-types')).IssueTypesModule
          }
        ]
      }
    ])
  ],
  exports: [RouterModule]
})
export class AppRouterModule {}
