import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { MembersService, OrganizationsService } from '../services';
import { AdminAnalyticsEffects } from '../store/admin-analytics.effects';
import { MembersEffects } from '../store/members.effects';
import { membersReducer } from '../store/members.reducer';
import { OrgQuotasEffects } from '../store/org-quotas.effects';
import { orgQuotasReducer } from '../store/org-quotas.reducer';
import { OrgRolesEffects } from '../store/org-roles.effects';
import { orgRolesReducer } from '../store/org-roles.reducer';
import { OrganizationsEffects } from '../store/organizations.effects';
import { organizationsReducer } from '../store/organizations.reducer';

@NgModule({
  imports: [
    StoreModule.forFeature('members', membersReducer),
    StoreModule.forFeature('orgQuotas', orgQuotasReducer),
    StoreModule.forFeature('orgRoles', orgRolesReducer),
    StoreModule.forFeature('organizations', organizationsReducer),

    EffectsModule.forFeature([
      AdminAnalyticsEffects,
      MembersEffects,
      OrgQuotasEffects,
      OrgRolesEffects,
      OrganizationsEffects
    ])
  ],
  providers: [MembersService, OrganizationsService]
})
export class OrganizationsApiModule {}
