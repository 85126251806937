import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import {
  Observable,
  catchError,
  exhaustMap,
  filter,
  map,
  of,
  switchMap,
  tap,
  withLatestFrom
} from 'rxjs';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { Organization, Scope } from '@neuralegion/api';
import { selectScopePermission } from '@neuralegion/auth-api';
import { loadProducts, loadSummary } from '../../billing-admin-api';
import { OrganizationsService } from '../services';
import { clearOrgMembers, loadMembers } from './members.actions';
import { clearOrgQuotas } from './org-quotas.actions';
import { clearOrgRoles, loadOrgRoles } from './org-roles.actions';
import {
  addOrganization,
  addOrganizationFail,
  addOrganizationSuccess,
  loadOrganization,
  loadOrganizationFail,
  loadOrganizationSuccess,
  loadOrganizations,
  loadOrganizationsFail,
  loadOrganizationsSuccess,
  removeOrganization,
  removeOrganizationFail,
  removeOrganizationSuccess,
  setTargetDomainOptions,
  setTargetDomainOptionsFail,
  setTargetDomainOptionsSuccess,
  updateOrganization,
  updateOrganizationFail,
  updateOrganizationSuccess,
  updatePermissions,
  updatePermissionsFail,
  updatePermissionsSuccess,
  updateTags,
  updateTagsFail,
  updateTagsSuccess,
  uploadTargetDomainOptions,
  uploadTargetDomainOptionsFail,
  uploadTargetDomainOptionsSuccess
} from './organizations.actions';

@Injectable()
export class OrganizationsEffects {
  public readonly loadOrganizations$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(loadOrganizations),
      withLatestFrom(this.store.select(selectScopePermission(Scope.ADMIN_ORGS))),
      exhaustMap(([, adminOrgsPermission]: [ReturnType<typeof loadOrganizations>, boolean]) =>
        adminOrgsPermission
          ? this.organizationsService.loadOrganizations().pipe(
              map((organizations: Organization[]) => loadOrganizationsSuccess(organizations)),
              catchError((err: HttpErrorResponse) => of(loadOrganizationsFail(err.error)))
            )
          : of(loadOrganizationsSuccess([]))
      )
    )
  );

  public readonly loadOrganization$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(loadOrganization),
      exhaustMap((action: ReturnType<typeof loadOrganization>) =>
        this.organizationsService.loadOrganization(action.payload.orgId).pipe(
          map((organization: Organization) => loadOrganizationSuccess(organization)),
          catchError((err: HttpErrorResponse) => of(loadOrganizationFail(err.error)))
        )
      )
    )
  );

  public readonly loadOrganizationFail$: Observable<Action> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(loadOrganizationFail),
        tap(() => this.router.navigate(['/']))
      ),
    { dispatch: false }
  );

  public readonly addOrganization$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(addOrganization),
      exhaustMap((action: ReturnType<typeof addOrganization>) =>
        this.organizationsService.addOrganization(action.payload).pipe(
          map(() => addOrganizationSuccess()),
          catchError((err: HttpErrorResponse) => of(addOrganizationFail(err.error)))
        )
      )
    )
  );

  public readonly removeOrganization$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(removeOrganization),
      exhaustMap((action: ReturnType<typeof removeOrganization>) =>
        this.organizationsService.removeOrganization(action.payload.orgId).pipe(
          map(() => removeOrganizationSuccess({ orgId: action.payload.orgId })),
          catchError((err: HttpErrorResponse) => of(removeOrganizationFail(err.error)))
        )
      )
    )
  );

  public readonly removeOrganizationPieces$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(removeOrganization),
      switchMap(({ payload: { orgId } }: ReturnType<typeof removeOrganization>) => [
        clearOrgMembers({ orgId }),
        clearOrgRoles({ orgId }),
        clearOrgQuotas({ orgId })
      ])
    )
  );

  public readonly updateOrganization$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(updateOrganization),
      exhaustMap((action: ReturnType<typeof updateOrganization>) =>
        this.organizationsService
          .updateOrganization(action.payload.orgId, action.payload.organization)
          .pipe(
            map(() => updateOrganizationSuccess({ orgId: action.payload.orgId })),
            catchError((err: HttpErrorResponse) => of(updateOrganizationFail(err.error)))
          )
      )
    )
  );

  public readonly updatePermissions$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(updatePermissions),
      exhaustMap((action: ReturnType<typeof updatePermissions>) =>
        this.organizationsService
          .updatePermissions(action.payload.orgId, action.payload.permissions)
          .pipe(
            map(() => updatePermissionsSuccess({ orgId: action.payload.orgId })),
            catchError((err: HttpErrorResponse) => of(updatePermissionsFail(err.error)))
          )
      )
    )
  );

  public readonly updateTags$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(updateTags),
      switchMap((action: ReturnType<typeof updateTags>) =>
        this.organizationsService.updateTags(action.payload.orgId, action.payload.tags).pipe(
          map(() => updateTagsSuccess({ orgId: action.payload.orgId })),
          catchError((err: HttpErrorResponse) => of(updateTagsFail(err.error)))
        )
      )
    )
  );

  public readonly setTargetDomainOptions$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(setTargetDomainOptions),
      exhaustMap((action: ReturnType<typeof setTargetDomainOptions>) =>
        this.organizationsService
          .setTargetDomainOptions(action.payload.orgId, action.payload.authorizedTargets)
          .pipe(
            map(() => setTargetDomainOptionsSuccess({ orgId: action.payload.orgId })),
            catchError((err: HttpErrorResponse) => of(setTargetDomainOptionsFail(err.error)))
          )
      )
    )
  );

  public readonly uploadTargetDomainOptions$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(uploadTargetDomainOptions),
      exhaustMap((action: ReturnType<typeof uploadTargetDomainOptions>) =>
        this.organizationsService
          .uploadTargetDomainOptions(action.payload.orgId, action.payload.file)
          .pipe(
            map(() => uploadTargetDomainOptionsSuccess({ orgId: action.payload.orgId })),
            catchError((err: HttpErrorResponse) => of(uploadTargetDomainOptionsFail(err.error)))
          )
      )
    )
  );

  public readonly redirectToOrganizationsPage$: Observable<Action> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(removeOrganizationSuccess),
        tap(() => this.router.navigate(['/organizations']))
      ),
    { dispatch: false }
  );

  public readonly reloadOrganization$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(
        setTargetDomainOptionsSuccess,
        uploadTargetDomainOptionsSuccess,
        updateOrganizationSuccess,
        updateTagsSuccess,
        updatePermissionsSuccess
      ),
      map(({ payload: { orgId } }: { payload: { orgId: string } }) =>
        loadOrganization({ orgId, skipRelated: true })
      )
    )
  );

  public readonly reloadOrganizations$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(addOrganizationSuccess),
      map(() => loadOrganizations())
    )
  );

  public readonly closeDialogWindows$: Observable<Action> = createEffect(
    () =>
      this.actions$.pipe(
        ofType<Action>(addOrganization),
        tap(() => this.dialog.closeAll())
      ),
    { dispatch: false }
  );

  public readonly loadRelated$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(loadOrganization),
      filter((action: ReturnType<typeof loadOrganization>) => !action.payload.skipRelated),
      exhaustMap((action: ReturnType<typeof loadOrganization>) => {
        const orgId = action.payload.orgId;
        return [
          loadMembers({ orgId }),
          loadOrgRoles({ orgId }),
          loadProducts(),
          loadSummary({ customerId: orgId })
        ];
      })
    )
  );

  constructor(
    private readonly actions$: Actions,
    private readonly dialog: MatDialog,
    private readonly router: Router,
    private readonly store: Store,
    private readonly organizationsService: OrganizationsService
  ) {}
}
