import { createAction } from '@ngrx/store';
import { Quota } from '@neuralegion/api';

export const loadQuotas = createAction('[OrgQuotas] LOAD_QUOTAS', (payload: { orgId: string }) => ({
  payload
}));

export const loadQuotasFail = createAction('[OrgQuotas] LOAD_QUOTAS_FAIL', (payload: string) => ({
  payload,
  parentType: loadQuotas.type
}));

export const loadQuotasSuccess = createAction(
  '[OrgQuotas] LOAD_QUOTAS_SUCCESS',
  (payload: { orgId: string; quotas: Quota[] }) => ({ payload, parentType: loadQuotas.type })
);

export const updateQuota = createAction(
  '[OrgQuotas] UPDATE_QUOTA',
  (payload: { orgId: string; quota: Quota }) => ({
    payload
  })
);

export const updateQuotaSuccess = createAction(
  '[OrgQuotas] UPDATE_QUOTA_SUCCESS',
  (payload: { orgId: string; quota: Quota }) => ({ payload, parentType: updateQuota.type })
);

export const updateQuotaFail = createAction('[OrgQuotas] UPDATE_QUOTA_FAIL', (payload: string) => ({
  payload,
  parentType: updateQuota.type
}));

export const clearOrgQuotas = createAction(
  '[OrgQuotas] CLEAR_ORG_QUOTAS',
  (payload: { orgId: string }) => ({
    payload
  })
);
