import { createAction } from '@ngrx/store';
import { OrgRole } from './org-roles.state';

export const loadOrgRoles = createAction(
  '[OrgRoles] LOAD_ORG_ROLES',
  (payload: { orgId: string }) => ({ payload })
);

export const loadOrgRolesFail = createAction(
  '[OrgRoles] LOAD_ORG_ROLES_FAIL',
  (payload: string) => ({
    payload,
    parentType: loadOrgRoles.type
  })
);

export const loadOrgRolesSuccess = createAction(
  '[OrgRoles] LOAD_ORG_ROLES_SUCCESS',
  (payload: { roles: OrgRole[] }) => ({ payload, parentType: loadOrgRoles.type })
);

export const clearOrgRoles = createAction(
  '[OrgRoles] CLEAR_ORG_ROLES',
  (payload: { orgId: string }) => ({ payload })
);
