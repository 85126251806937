import { Action, createReducer, on } from '@ngrx/store';
import { Member } from '@neuralegion/api';
import { cleanupPendingState } from '@neuralegion/core';
import {
  addMember,
  addMemberFail,
  addMemberSuccess,
  clearOrgMembers,
  loadMember,
  loadMemberFail,
  loadMemberSuccess,
  loadMembers,
  loadMembersFail,
  loadMembersSuccess,
  removeMember,
  removeMemberFail,
  removeMemberSuccess,
  updateMember,
  updateMemberFail,
  updateMemberSuccess
} from './members.actions';
import { MembersState, membersEntityAdapter, membersInitialState } from './members.state';

export const membersReducer = createReducer<MembersState, Action>(
  membersInitialState,
  on(
    loadMember,
    addMember,
    updateMember,
    removeMember,
    loadMembers,
    (state: MembersState, action): MembersState => ({
      ...state,
      pending: [...state.pending, action]
    })
  ),
  on(
    loadMemberFail,
    addMemberFail,
    updateMemberFail,
    removeMemberFail,
    loadMembersFail,
    (state: MembersState, action): MembersState =>
      cleanupPendingState(state, action, action.payload)
  ),
  on(
    loadMemberSuccess,
    (state: MembersState, action): MembersState =>
      membersEntityAdapter.upsertOne(action.payload.member, cleanupPendingState(state, action))
  ),
  on(
    addMemberSuccess,
    updateMemberSuccess,
    (state: MembersState, action): MembersState => cleanupPendingState(state, action)
  ),
  on(
    removeMemberSuccess,
    (state: MembersState, action): MembersState =>
      membersEntityAdapter.removeOne(action.payload.memberId, cleanupPendingState(state, action))
  ),
  on(
    loadMembersSuccess,
    (state: MembersState, action): MembersState =>
      membersEntityAdapter.addMany(action.payload.members, cleanupPendingState(state, action))
  ),
  on(
    clearOrgMembers,
    (state: MembersState, action): MembersState =>
      membersEntityAdapter.removeMany(
        (item: Member) => item.organizationId === action.payload.orgId,
        state
      )
  )
);
