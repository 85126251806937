import { createAction } from '@ngrx/store';
import { BillingSummary, Product } from '@neuralegion/billing-api';

export const loadSummary = createAction(
  '[BillingAdmin] LOAD_SUMMARY',
  (payload: { customerId: string }) => ({
    payload
  })
);

export const loadSummarySuccess = createAction(
  '[BillingAdmin] LOAD_SUMMARY_SUCCESS',
  (payload: { summary: BillingSummary }) => ({ payload, parentType: loadSummary.type })
);

export const loadSummaryFail = createAction(
  '[BillingAdmin] LOAD_SUMMARY_FAIL',
  (payload: { error: string }) => ({
    payload,
    parentType: loadSummary.type
  })
);

export const loadProducts = createAction('[BillingAdmin] LOAD_PRODUCTS');

export const loadProductsSuccess = createAction(
  '[BillingAdmin] LOAD_PRODUCTS_SUCCESS',
  (payload: { products: Product[] }) => ({ payload, parentType: loadProducts.type })
);

export const loadProductsFail = createAction(
  '[BillingAdmin] LOAD_PRODUCTS_FAIL',
  (payload: { error: string }) => ({
    payload,
    parentType: loadProducts.type
  })
);

export const addSubscription = createAction(
  '[BillingAdmin] ADD_SUBSCRIPTION',
  (payload: { planId: string; orgId: string }) => ({ payload })
);

export const addSubscriptionFail = createAction(
  '[BillingAdmin] ADD_SUBSCRIPTION_FAIL',
  (payload: string) => ({
    payload,
    parentType: addSubscription.type
  })
);

export const addSubscriptionSuccess = createAction(
  '[BillingAdmin] ADD_SUBSCRIPTION_SUCCESS',
  (payload: { customerId: string }) => ({ payload, parentType: addSubscription.type })
);

export const changeSubscription = createAction(
  '[BillingAdmin] CHANGE_SUBSCRIPTION',
  (payload: { planId: string; orgId: string; subscriptionId: string }) => ({ payload })
);

export const changeSubscriptionFail = createAction(
  '[BillingAdmin] CHANGE_SUBSCRIPTION_FAIL',
  (payload: string) => ({
    payload,
    parentType: changeSubscription.type
  })
);

export const changeSubscriptionSuccess = createAction(
  '[BillingAdmin] CHANGE_SUBSCRIPTION_SUCCESS',
  (payload: { customerId: string }) => ({ payload, parentType: changeSubscription.type })
);

export const removeSubscription = createAction(
  '[BillingAdmin] REMOVE_SUBSCRIPTION',
  (payload: { orgId: string; subscriptionId: string }) => ({
    payload
  })
);

export const removeSubscriptionFail = createAction(
  '[BillingAdmin] REMOVE_SUBSCRIPTION_FAIL',
  (payload: string) => ({
    payload,
    parentType: removeSubscription.type
  })
);

export const removeSubscriptionSuccess = createAction(
  '[BillingAdmin] REMOVE_SUBSCRIPTION_SUCCESS',
  (payload: { customerId: string }) => ({
    payload,
    parentType: removeSubscription.type
  })
);
