import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, exhaustMap, map, mergeMap, of } from 'rxjs';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Quota } from '@neuralegion/api';
import { OrganizationsService } from '../services';
import {
  loadQuotas,
  loadQuotasFail,
  loadQuotasSuccess,
  updateQuota,
  updateQuotaFail,
  updateQuotaSuccess
} from './org-quotas.actions';

@Injectable()
export class OrgQuotasEffects {
  public readonly loadQuotas$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(loadQuotas),
      exhaustMap((action: ReturnType<typeof loadQuotas>) =>
        this.organizationsService.loadQuotas(action.payload.orgId).pipe(
          map((quotas: Quota[]) => loadQuotasSuccess({ orgId: action.payload.orgId, quotas })),
          catchError((err: HttpErrorResponse) => of(loadQuotasFail(err.error)))
        )
      )
    )
  );

  public readonly updateQuota$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(updateQuota),
      mergeMap(
        ({ payload: { orgId, quota } }: ReturnType<typeof updateQuota>): Observable<Action> =>
          this.organizationsService.updateQuota(orgId, quota).pipe(
            map(() => updateQuotaSuccess({ orgId, quota })),
            catchError((err: HttpErrorResponse) => of(updateQuotaFail(err.error)))
          )
      )
    )
  );

  constructor(
    private readonly actions$: Actions,
    private readonly organizationsService: OrganizationsService
  ) {}
}
